import { spacing, Flex } from '@pelotoncycle/design-system';
import React from 'react';
import type { FC } from 'react';
import { useMedia } from 'react-use';
import styled, { keyframes } from 'styled-components';
import { BreakpointWidth } from '@peloton/styles';
import { Account } from './Account/Account';
import { Cart } from './Cart/Cart';
import { GeoPicker } from './GeoPicker/GeoPicker';
import { HamburgerMenu } from './HamburgerNav/HamburgerMenu';
import type {
  AccountProps,
  CartProps,
  GeoPickerProps,
  HamburgerMenuProps,
  ProductSubNavProps,
} from './types';

type IconRowProps = {
  geoPickerMenu?: GeoPickerProps;
  accountMenu?: AccountProps;
  cart?: CartProps;
  hamburgerMenu?: HamburgerMenuProps;
  productSubNavs: ProductSubNavProps[];
};

export const IconRow: FC<IconRowProps> = ({
  geoPickerMenu,
  accountMenu,
  cart,
  hamburgerMenu,
  productSubNavs,
}) => {
  const isDesktop = useMedia(`(min-width: ${BreakpointWidth.desktopLarge}px)`);
  return (
    <StyledFlex
      justifyContent="flex-end"
      alignItems="center"
      gap={spacing[24]}
      style={{ height: '100%', zIndex: 1 }}
      data-test-id="iconRow"
    >
      {isDesktop && geoPickerMenu?.displayGeoPickerInTopNav && (
        <GeoPicker
          eyebrowText={geoPickerMenu?.eyebrowText}
          links={geoPickerMenu?.links}
        />
      )}
      {accountMenu?.displayAccount && (
        <Account
          title={accountMenu?.title}
          links={accountMenu?.links}
          authText={accountMenu?.authText}
        />
      )}
      {cart?.displayCart && <Cart />}
      {hamburgerMenu?.displayHamburgerMenu && (
        <HamburgerMenu productSubNavs={productSubNavs} geoPickerMenu={geoPickerMenu} />
      )}
    </StyledFlex>
  );
};

const fadeInKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StyledFlex = styled(Flex)`
  animation: ${fadeInKeyframes} 1.5s ease;
`;
