import { white, Flex, Icon } from '@pelotoncycle/design-system';
import React, { useContext } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import IconWrapper from '../Icons/IconWrapper';
import { NavMenuArrow as MenuArrowIcon } from '../Icons/NavMenuArrow';
import { NavContext } from '../NavProvider';
import { baseTopNavStyles, navHoverOpacity } from '../styles';
import type { AccountProps } from '../types';
import { isNavMenuOpen } from '../utils';
import { AccountMenu } from './AccountMenu';

export const Account: FC<Pick<AccountProps, 'title' | 'links' | 'authText'>> = ({
  title,
  links,
  authText,
}) => {
  const { selectedNavItem, setSelectedNavItem } = useContext(NavContext);
  const isMenuOpen = isNavMenuOpen(selectedNavItem, title);

  const clickHandler = () => {
    const navItem = isMenuOpen ? null : title;
    setSelectedNavItem(navItem);
  };

  return (
    <Flex
      position="relative"
      style={{
        height: '100%',
      }}
    >
      <StyledAccountButton onClick={clickHandler}>
        <IconWrapper minWidth={32}>
          <StyledAccountIcon name="account" height={32} primaryColor={white} rotate={0} />
        </IconWrapper>
        <MenuArrowIcon isMenuOpen={isMenuOpen} />
      </StyledAccountButton>
      {isMenuOpen && <AccountMenu links={links} authText={authText} />}
    </Flex>
  );
};

export default Account;

const StyledAccountButton = styled.button`
  ${baseTopNavStyles}
`;

const StyledAccountIcon = styled(Icon)`
  ${navHoverOpacity}
`;
