import { white, Flex, Icon } from '@pelotoncycle/design-system';
import React, { useContext } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import IconWrapper from '../Icons/IconWrapper';
import { NavMenuArrow as MenuArrowIcon } from '../Icons/NavMenuArrow';
import { NavContext } from '../NavProvider';
import { baseTopNavStyles, navHoverOpacity } from '../styles';
import type { GeoPickerProps } from '../types';
import { isNavMenuOpen } from '../utils';
import { GeoPickerMenu } from './GeoPickerMenu';

export const GeoPicker: FC<Pick<GeoPickerProps, 'eyebrowText' | 'links'>> = ({
  eyebrowText,
  links,
}) => {
  const { selectedNavItem, setSelectedNavItem } = useContext(NavContext);
  const isMenuOpen = isNavMenuOpen(selectedNavItem, eyebrowText);

  const clickHandler = () => {
    const navItem = isMenuOpen ? null : eyebrowText;
    setSelectedNavItem(navItem);
  };

  return (
    <Flex
      position="relative"
      style={{
        height: '100%',
      }}
    >
      <StyledGeoPickerButton onClick={clickHandler}>
        <IconWrapper minWidth={32}>
          <StyledGeoPickerIcon
            name="location"
            height={32}
            primaryColor={white}
            rotate={0}
          />
        </IconWrapper>
        <MenuArrowIcon isMenuOpen={isMenuOpen} />
      </StyledGeoPickerButton>
      {isMenuOpen && <GeoPickerMenu eyebrowText={eyebrowText} links={links} />}
    </Flex>
  );
};

export default GeoPicker;

const StyledGeoPickerButton = styled.button`
  ${baseTopNavStyles}
`;

const StyledGeoPickerIcon = styled(Icon)`
  ${navHoverOpacity}
`;
